@import "@/shared/styles/colors.scss";
@import "@/shared/styles/fonts.scss";

/* デザイン通りに2行目にellipsisを利用するため-webkit-box +-webkit-line-clampを利用 
 * IE以外では利用できる。
 * line-clampがDraftに上がっているのでそちらが各ブラウザで対応されたらline-clampに変更する。
 * See: 
 * https://developer.mozilla.org/ja/docs/Web/CSS/-webkit-line-clamp
 * https://caniuse.com/?search=line-clamp
 */
.feedback_comment_label {
  display: -webkit-box; /* フレックスボックスを使用(line-clamp利用のため) */
  color: $sub-text-color;
  font-family: $primary-font-family;
  font-size: $tertiary_font_size;
  font-weight: $normal-font-weight;
  max-width: 300px;
  max-height: 40px;
  overflow: hidden; /* コンテンツが領域を超えた場合に非表示にする */
  text-overflow: ellipsis; /* テキストが領域を超えた場合に省略記号(...)を表示する */
  white-space: normal; /* テキストを改行させない */
  -webkit-line-clamp: 2; /* 表示する行数を制限 */
  -webkit-box-orient: vertical; /* テキストを縦方向に並べる */
}
