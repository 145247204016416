@import "@/shared/styles/fonts.scss";

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
}

.file_upload_button {
  display: flex;
  gap: 9px;
  align-items: center;
}

.file_upload_icon {
  width: 18px;
  height: 18px;
}

.file_upload_paragraph {
  text-align: center;
  font-family: $primary-font-family;
  font-style: normal;
  font-weight: $normal-font-weight;
  font-size: $secondary_font_size;
  line-height: 20px;
  p {
    margin: 0;
  }
}
