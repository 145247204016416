@import "../../../../shared/styles/colors.scss";
@import "../../../../shared/styles/fonts.scss";

.input_container {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.input {
  height: 40px;
  padding: 10px;
  background: $white-color;
  border: 1px solid $light-gray-color;
  border-radius: 4px;
  font-size: $secondary_font_size;
}

.label {
  font-family: $primary-font-family;
  font-size: $secondary_font_size;
  font-weight: $normal-font-weight;
}
