.notification-type {
  display: inline-block;
  padding: 4px 8px;
  border: 1px solid;
  color: #323232;
  border-color: #323232;
}
.notification-wrapper {
  cursor: pointer;
}
.notification-wrap-title {
  margin-top: 1em;
  color: #323232;
  cursor: pointer;
  white-space: nowrap;
  max-width: 60vw;
  overflow: hidden;
  text-overflow: ellipsis;
}
.tiny-dot {
  height: 7px;
  width: 7px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 14px;
}
