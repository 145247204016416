.comment_info_label {
  display: flex;
  align-items: center;
  gap: 8px 36px;
  flex-wrap: wrap;
  width: 100%;
}

.commented_persons_type_tag {
  margin-right: 0;
}

.commented_date {
  min-width: 84px;
}
