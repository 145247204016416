@import "../../../../shared/styles/colors.scss";

.down_icon_container {
  margin: 10px 0;
}

.items_container {
  width: 45vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.down_icon {
  font-size: 50px;
  color: $light-gray-color;
}

.create_btn {
  border: 1px solid $primary-color;
  color: $primary-color;
}

.header_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
