@import "@/shared/styles/colors.scss";
@import "@/shared/styles/fonts.scss";

.project_info_cell_container {
  background-color: transparent;
  box-sizing: border-box;
  border: none;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  grid-gap: 8px;
  width: 100%;
  height: 100%;
}

.project_info_cell_inner_row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
}

.project_info_cell_tag_area {
  grid-column-gap: 8px;
  column-gap: 8px;
}

.project_info_cell_sub_info_area {
  grid-column-gap: 16px;
  column-gap: 16px;
}
