@import "@/shared/styles/colors.scss";
@import "@/shared/styles/fonts.scss";

.label {
  color: $normal-text-color;
  background: $bg-light-gray-color;
  border-radius: 4px;
  padding: 0 8px;
  font-size: $tertiary_font_size;
  display: flex;
  align-items: center;
}
