.save_btn {
  margin: 0 10px;
}

.cancel_btn {
  margin: 0 10px;
}

.btn_container {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}
