.item {
  display: flex;
  justify-content: space-between;
}

.label {
  margin: 0;
}

.divider {
  margin: 6px 0 0 0;
}
