@import "@/shared/styles/colors.scss";

.event_info {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.event_recommendation {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 0;
  padding-left: 32px;
  li {
    list-style: disc;
  }
}
