.content {
  display: flex;
  flex-direction: column;
  gap: 40px 0;
}

.title {
  font-size: 18px;
}

.description {
  display: flex;
  flex-direction: column;
  gap: 10px 0;
}

.form_item {
  margin: 0;
}
