@import "../../../../shared/styles/colors.scss";

.flag_icon {
  margin: 0;
}

.active {
  color: $primary-color;
}

.inactive {
  color: $secondary-color;
}

.switch_btn {
  padding: 0 10px;
  box-shadow: none;
}

.container {
  margin: 0 10px;
}

.flag_icon_container {
  margin: 0 10px;
}
