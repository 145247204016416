.commented_person_link {
  font-weight: bold;
  color: #000;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.commented_persons_name {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 300px;
}
