@import "@/shared/styles/colors.scss";
@import "@/shared/styles/fonts.scss";

.status_published_label {
  color: $normal-text-color;
  font-family: $primary-font-family;
  font-size: $tag_font_size;
  font-weight: $normal-font-weight;
  &::before {
    content: "";
    display: inline-block;
    margin-right: 4px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: $success-color;
    border: 0.5px solid $success-color;
  }
}

.status_before_publish_label {
  color: $sub-text-color;
  font-family: $primary-font-family;
  font-size: $tag_font_size;
  font-weight: $normal-font-weight;
  &::before {
    content: "";
    display: inline-block;
    margin-right: 4px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: transparent;
    border: 0.5px solid $black-color;
  }
}
