@import "@/shared/styles/colors.scss";

.image_preview {
  max-width: 250px;
  max-height: 150px;
  &_container {
    position: relative;
  }
  &_delete_button {
    position: absolute;
    top: 0;
    left: 5px;
  }
}
