@import "@/shared/styles/colors.scss";

.event_body {
  background-color: white;
  padding: 24px;
  display: flex;
  justify-content: space-between;
  gap: 64px;
}

.event_info {
  flex-basis: 60%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 60%;
}

.event_title_wrapper {
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.event_schedule_and_location {
  display: flex;
  flex-direction: row;
  gap: 8px 24px;
  flex-wrap: wrap;
}

.event_description {
  white-space: pre-wrap;
}

.event_application_container {
  flex-basis: 40%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  width: 40%;
}
