.list {
  display: block;
  margin: auto;
  width: 40px;
  height: 80px;
  svg {
    width: 100%;
    height: 100%;
  }
}

.gallery {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;

  svg {
    width: 48px;
    height: 48px;
  }
}
