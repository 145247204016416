@import "./../../../styles/colors.scss";
@import "./../../../styles/fonts.scss";

.link_button {
  cursor: pointer;
  border: none;
  padding: 0;
  background-color: transparent;
  color: $primary-color;
  font-family: $primary-font-family;
  transition: 0.3s;
  &:hover {
    color: $primary-color-hover;
    transition: 0.3s;
  }
}
