@import "@/shared/styles/colors.scss";

.container {
  padding: 24px;
  gap: 10px;
  background: $action-button-background-color;
}

.event_form {
  max-width: 640px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  padding: 60px 0;
  gap: 60px;
}

.event_buttons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 8px;
}

.cancel_button {
  font-weight: bold;
  width: 115px;
}
