@import "../../../../shared/styles/colors.scss";
@import "../../../../shared/styles/fonts.scss";

.container {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.editor {
  border-radius: 4px;
  border: 1px solid $secondary-border-color;
  background: $white-color;
  width: 100%;
  min-height: 120px;
  max-height: 400px;
  padding: 10px;
}
