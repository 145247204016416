@import "@/shared/styles/colors.scss";

.event_info {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.event_column {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.event_date_time {
  display: flex;
  flex-direction: row;
}

.event_type {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
}

.event_capacity {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 8px;
}

.mr_8 {
  margin-right: 8px;
}

.small_input {
  width: 150px;
}
