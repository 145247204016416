@import "../../../../shared/styles/colors.scss";
@import "../../../../shared/styles/fonts.scss";

.link {
  font-family: $primary-font-family;
  font-weight: $bold-font-weight;
  color: $primary-color;
  display: block;
  font-size: 12px;
  text-align: center;
  transition: 0.3s;
  &:hover {
    color: $primary-color-hover;
    transition: 0.3s;
  }
}
