@import "@/shared/styles/colors.scss";

.event_recommendations {
  display: flex;
  flex-direction: column;
  background-color: $bg-light-gray-color;
  padding: 24px;
  border-radius: 4px;
  gap: 8px;
}
