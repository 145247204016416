@import "../../../../shared/styles/colors.scss";

.upload_area_container {
  margin-top: 24px;
  width: 100%;
}

.upload_area_content {
  height: 164px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.upload_area_message {
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
  text-align: center;
}

.upload_area_message_container {
  margin-top: 20px;
}

.drop_area {
  height: 164px;
  padding: 20px;
}
