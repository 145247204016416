.rdw-dropdown-wrapper {
  height: 30px;
  background: white;
  cursor: pointer;
  border: 1px solid #f1f1f1;
  border-radius: 2px;
  margin: 0 3px;
  text-transform: capitalize;
  background: white;
}
.rdw-dropdown-wrapper:focus {
  outline: none;
}
.rdw-dropdown-wrapper:hover {
  box-shadow: 1px 1px 0px #bfbdbd;
  background-color: #ffffff;
}
.rdw-dropdown-wrapper:active {
  box-shadow: 1px 1px 0px #bfbdbd inset;
}
.rdw-dropdown-carettoopen {
  height: 0px;
  width: 0px;
  position: absolute;
  top: 35%;
  right: 10%;
  border-top: 6px solid black;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
}
.rdw-dropdown-carettoclose {
  height: 0px;
  width: 0px;
  position: absolute;
  top: 35%;
  right: 10%;
  border-bottom: 6px solid black;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
}
.rdw-dropdown-selectedtext {
  display: flex;
  position: relative;
  height: 100%;
  align-items: center;
  padding: 0 5px;
}
.rdw-dropdown-optionwrapper {
  z-index: 100;
  position: relative;
  border: 1px solid #f1f1f1;
  width: 98%;
  background: white;
  border-radius: 2px;
  margin: 0;
  padding: 0;
  max-height: 250px;
  overflow-y: scroll;
}
.rdw-dropdown-optionwrapper:hover {
  box-shadow: 1px 1px 0px #bfbdbd;
  background-color: #ffffff;
}
