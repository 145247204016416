.rdw-image-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 6px;
  position: relative;
  flex-wrap: wrap;
}
.rdw-image-modal {
  position: absolute;
  top: 35px;
  left: 5px;
  display: flex;
  flex-direction: column;
  width: 235px;
  border: 1px solid #f1f1f1;
  padding: 15px;
  border-radius: 2px;
  z-index: 100;
  background: white;
  box-shadow: 3px 3px 5px #bfbdbd;
}
.rdw-image-modal-header {
  font-size: 15px;
  margin: 10px 0;
  display: flex;
}
.rdw-image-modal-header-option {
  width: 50%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.rdw-image-modal-header-label {
  width: 80px;
  background: #f1f1f1;
  border: 1px solid #f1f1f1;
  margin-top: 5px;
}
.rdw-image-modal-header-label-highlighted {
  background: #6eb8d4;
  border-bottom: 2px solid #0a66b7;
}
.rdw-image-modal-upload-option {
  width: 100%;
  color: gray;
  cursor: pointer;
  display: flex;
  border: none;
  font-size: 15px;
  align-items: center;
  justify-content: center;
  background-color: #f1f1f1;
  outline: 2px dashed gray;
  outline-offset: -10px;
  margin: 10px 0;
  padding: 9px 0;
}
.rdw-image-modal-upload-option-highlighted {
  outline: 2px dashed #0a66b7;
}
.rdw-image-modal-upload-option-label {
  cursor: pointer;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
}
.rdw-image-modal-upload-option-label span {
  padding: 0 20px;
}
.rdw-image-modal-upload-option-image-preview {
  max-width: 100%;
  max-height: 200px;
}
.rdw-image-modal-upload-option-input {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
.rdw-image-modal-url-section {
  display: flex;
  align-items: center;
}
.rdw-image-modal-url-input {
  width: 90%;
  height: 35px;
  margin: 15px 0 12px;
  border: 1px solid #f1f1f1;
  border-radius: 2px;
  font-size: 15px;
  padding: 0 5px;
}
.rdw-image-modal-btn-section {
  margin: 10px auto 0;
}
.rdw-image-modal-url-input:focus {
  outline: none;
}
.rdw-image-modal-btn {
  margin: 0 5px;
  width: 75px;
  height: 30px;
  border: 1px solid #f1f1f1;
  border-radius: 2px;
  cursor: pointer;
  background: white;
  text-transform: capitalize;
}
.rdw-image-modal-btn:hover {
  box-shadow: 1px 1px 0px #bfbdbd;
}
.rdw-image-modal-btn:active {
  box-shadow: 1px 1px 0px #bfbdbd inset;
}
.rdw-image-modal-btn:focus {
  outline: none !important;
}
.rdw-image-modal-btn:disabled {
  background: #ece9e9;
}
.rdw-image-modal-spinner {
  position: absolute;
  top: -3px;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.5;
}
.rdw-image-modal-alt-input {
  width: 70%;
  height: 20px;
  border: 1px solid #f1f1f1;
  border-radius: 2px;
  font-size: 12px;
  margin-left: 5px;
}
.rdw-image-modal-alt-input:focus {
  outline: none;
}
.rdw-image-modal-alt-lbl {
  font-size: 12px;
}
.rdw-image-modal-size {
  align-items: center;
  display: flex;
  margin: 8px 0;
  justify-content: space-between;
}
.rdw-image-modal-size-input {
  width: 40%;
  height: 20px;
  border: 1px solid #f1f1f1;
  border-radius: 2px;
  font-size: 12px;
}
.rdw-image-modal-size-input:focus {
  outline: none;
}
.rdw-image-mandatory-sign {
  color: red;
  margin-left: 3px;
  margin-right: 3px;
}
