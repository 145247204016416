.user_type_tag {
  border: none;
  background: rgba(50, 50, 50, 0.1);
}
.user_type_tag_text {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 200%;
}
