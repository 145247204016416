@import "@/shared/styles/colors.scss";
@import "@/shared/styles/fonts.scss";

.search_container {
  border: 1px solid $secondary-border-color;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 4px;
  min-width: fit-content;
}
.search_input {
  flex: auto;
  color: $black-color;
  font-family: $primary-font-family;
  font-size: $secondary_font_size;
  font-weight: $normal-font-weight;
  letter-spacing: 0.02em;
  height: 24px;
  border: none;
  padding: 0;
  margin: 0 0 0 10px;
  outline: none;
  text-overflow: ellipsis;
  ::placeholder {
    color: $placeholder-color;
  }
}
.search_button {
  flex: initial;
  background-color: $white-color;
  border: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
  width: 38px;
  min-width: 38px;
  height: 40px;
}
.search_button_image {
  background-color: transparent;
  border: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
  width: 14px;
}

.search_separator {
  flex: initial;
  padding: 0;
  margin: 8px 0;
}
