@import "@/shared/styles/colors.scss";
@import "@/shared/styles/fonts.scss";

.input_container {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.select_box {
  font-weight: $normal-font-weight;
  font-size: $secondary_font_size;
  font-family: $primary-font-family;
  cursor: pointer;
}

.label {
  font-family: $primary-font-family;
  font-size: $secondary_font_size;
  font-weight: $normal-font-weight;
}

.select_indicators_container {
  margin-right: 16px;
  color: $black-color;
}

.clear_indicator_container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.clear_indicator {
  height: 20px;
  width: 20px;
  margin: auto;
}

:export {
  black_color: $black-color;
  error_color: $error-color;
  border_color: $secondary-border-color;
}
