.rdw-link-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 6px;
  position: relative;
  flex-wrap: wrap;
}
.rdw-link-dropdown {
  width: 50px;
}
.rdw-link-dropdownOption {
  height: 40px;
  display: flex;
  justify-content: center;
}
.rdw-link-dropdownPlaceholder {
  margin-left: 8px;
}
.rdw-link-modal {
  position: absolute;
  top: 35px;
  left: 5px;
  display: flex;
  flex-direction: column;
  width: 235px;
  height: 205px;
  border: 1px solid #f1f1f1;
  padding: 15px;
  border-radius: 2px;
  z-index: 100;
  background: white;
  box-shadow: 3px 3px 5px #bfbdbd;
}
.rdw-link-modal-label {
  font-size: 15px;
}
.rdw-link-modal-input {
  margin-top: 5px;
  border-radius: 2px;
  border: 1px solid #f1f1f1;
  height: 25px;
  margin-bottom: 15px;
  padding: 0 5px;
}
.rdw-link-modal-input:focus {
  outline: none;
}
.rdw-link-modal-buttonsection {
  margin: 0 auto;
}
.rdw-link-modal-target-option {
  margin-bottom: 20px;
}
.rdw-link-modal-target-option > span {
  margin-left: 5px;
}
.rdw-link-modal-btn {
  margin-left: 10px;
  width: 75px;
  height: 30px;
  border: 1px solid #f1f1f1;
  border-radius: 2px;
  cursor: pointer;
  background: white;
  text-transform: capitalize;
}
.rdw-link-modal-btn:hover {
  box-shadow: 1px 1px 0px #bfbdbd;
}
.rdw-link-modal-btn:active {
  box-shadow: 1px 1px 0px #bfbdbd inset;
}
.rdw-link-modal-btn:focus {
  outline: none !important;
}
.rdw-link-modal-btn:disabled {
  background: #ece9e9;
}
.rdw-link-dropdownoption {
  height: 40px;
  display: flex;
  justify-content: center;
}
.rdw-history-dropdown {
  width: 50px;
}
