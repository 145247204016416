$green-color: #00bf76;
$white-color: #fff;
$mild-black: #475865;
$purple-color: #6e3cf5;
$black-color: #000000;
$grey-color: #f0f0f0;
$dark-grey-color: #d9d9d9;
$light-blue: #40a9ff;
$red-color: #eb3030;
$strong-orange-color: #fd9226;
$light-orange-color: #fec67d;
$text-orange-color: #fa8006;

$strong-pink-color: #d84b7c;
$light-pink-color: #d12e66;
$light-pink-color-10: #d12e661a;

$comment-bg-color: #ffeff6;
$light-background-color: #f8f1ff;
$checkbox-bg-color: #eee;
$disabled-grey-color: #5d5d5d;
$insu-grey-bg: #e9edf5;
$insu-primary: #1f86e9;
$side-bg: #475865;
$side-project-bg: #35414b;
$insu-navy: #272c41;
$insu-light-grey: #fafafa;
$insu-gray: #ebebeb;
$insu-purple: #5b3395;
$insu-green-grey: #475865;
$insu-table-header-bg: #ececec;
$insu-black: #323232;
$insu-bg-light-blue: #e9edf5;
