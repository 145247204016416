@import "../../../styles/fonts.scss";
@import "../../../styles/colors.scss";

.option {
  &_button {
    width: 100%;
    background-color: transparent;
    border: 0;
    margin-bottom: 8px;
    cursor: pointer;
    padding: 0;
  }
  &_item {
    display: flex;
    align-items: center;
    gap: 12px;
    cursor: pointer;
  }
  &_label {
    font-size: $secondary_font_size;
    font-family: $primary-font-family;
  }
  &_label_checked {
    color: $primary-text-color;
  }
}

.menu_list_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 24px 24px 16px;
}

.reset_button {
  background-color: transparent;
  border: 0;
  margin-top: 16px;
  &_label {
    font-size: $tertiary_font_size;
    font-family: $primary-font-family;
    &_unchecked {
      cursor: not-allowed;
      color: $disable-text-color;
    }
    &_checked {
      cursor: pointer;
      color: $secondary-text-color;
    }
  }
}
