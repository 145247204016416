.rdw-editor-main {
  height: 100%;
  overflow: visible;
  box-sizing: border-box;
  padding: 12px 0px;
}
.rdw-editor-main h6 {
  margin-bottom: 0px !important;
  font-weight: 400 !important;
  line-height: 0.6;
}
.rdw-editor-toolbar {
  padding: 6px 5px 0;
  border-radius: 2px;
  border: 1px solid #f1f1f1;
  display: flex;
  justify-content: flex-start;
  background: white;
  flex-wrap: wrap;
  font-size: 15px;
  margin-bottom: 5px;
  user-select: none;
  position: sticky;
  top: 0;
  z-index: 2;
}
.rdw-editor-toolbar-bottom {
  padding: 6px 5px 0;
  border-radius: 2px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: white;
  flex-wrap: wrap;
  font-size: 15px;
  margin-bottom: 5px;
  user-select: none;
}
.public-DraftStyleDefault-block {
  margin: 0 0 !important;
}
.rdw-editor-wrapper:focus {
  outline: none;
}
.rdw-editor-wrapper {
  box-sizing: content-box;
}
.rdw-editor-main blockquote {
  border-left: 5px solid #f1f1f1;
  padding-left: 5px;
}
.rdw-editor-main pre {
  background: #f1f1f1;
  border-radius: 3px;
  padding: 1px 10px;
}
