@import "@/shared/styles/colors.scss";
@import "@/shared/styles/fonts.scss";

.event_recommendations_wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.event_recommendations_title_wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
}

.event_recommendations_title {
  font-family: $primary-font-family;
  font-weight: $normal-font-weight;
  font-size: $tertiary_font_size;
  color: $black-color;
}

.event_recommendations_title_line {
  border: 1px solid #dddddd;
  height: 0;
  flex-grow: 1;
}

.event_recommendations_list {
  margin-bottom: 0;
  padding-left: 20px;
  font-family: $primary-font-family;
  font-weight: $bold-font-weight;
  font-size: $tertiary_font_size;
  color: $black-color;
  list-style: disc;
  li {
    list-style-type: disc;
  }
}
