@import "@/shared/styles/colors.scss";
@import "@/shared/styles/fonts.scss";

.root {
  padding-top: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  min-height: 80vh;

  * {
    font-family: $primary-font-family;
  }
}

.unexpected_error_occured {
  font-size: $heading2_font_size;
  font-weight: $bold-font-weight;
  line-height: 26px;
  letter-spacing: 0.01em;
  text-align: center;
}

.logo {
  width: 160px;
}

.info {
  width: 520px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
}

.list_disc {
  list-style-type: disc;
}

.try_this {
  width: 100%;
  padding: 16px;
  gap: 8px;
  border: 1px solid $secondary-border-color;
  border-radius: 8px;
}

.try_following {
  font-size: $secondary_font_size;
  font-weight: $bold-font-weight;
  line-height: 24px;
  letter-spacing: 0.02em;
}

.try_following_list {
  margin: 0;
  font-size: $secondary_font_size;
  font-weight: $normal-font-weight;
  line-height: 24px;
  letter-spacing: 0.02em;
}

.let_us_know_via_email {
  font-size: $secondary_font_size;
  font-weight: $normal-font-weight;
  line-height: 24px;
  letter-spacing: 0.02em;
}

.let_us_know_small {
  font-size: $tag_font_size;
  font-weight: $normal-font-weight;
  line-height: 14px;
  letter-spacing: 0em;
  margin-bottom: 4px;
}
