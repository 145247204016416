.title {
  font-weight: bold;
  font-size: 18px;
  line-height: 28.8px;
  letter-spacing: 4%;
}

.title_container {
  margin-top: 40px;
}
