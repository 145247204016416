.modal {
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.player {
  width: 1000px;
  height: 562.5px;
}

:export {
  player_width: 1000px;
  player_height: 562.5px;
}
