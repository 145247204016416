@import "../../../../shared/styles/colors.scss";
@import "../../../../shared/styles/fonts.scss";

.button {
  height: 32px;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  height: 40px;
  gap: 0px 10px;
  &_font {
    color: $normal-text-color;
    font-family: $primary-font-family;
    font-weight: $bold-font-weight;
    font-size: $secondary_font_size;
    white-space: nowrap;
  }
}
