@import "@/shared/styles/colors.scss";
@import "@/shared/styles/fonts.scss";

.review_info_row_container {
  background-color: transparent;
  border-bottom: 1px solid $grid-row-border;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 32px;
  width: 100%;
  padding: 16px 24px 16px 24px;
  &:hover {
    background-color: $hover-background-color;
  }
}

.review_info_row_link {
  display: flex;
  align-items: center;
  flex-grow: 1;
  gap: 32px;
}

.review_info_row_checkbox {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 0;
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.review_info_row_project_info {
  flex-grow: 2.5;
  flex-basis: 0;
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: flex-start;
  justify-content: center;
}

.review_info_row_review_info {
  flex-grow: 1;
  flex-basis: 0;
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
  justify-content: center;
}
