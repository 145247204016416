@import "@/shared/styles/colors.scss";
@import "@/shared/styles/fonts.scss";

.label {
  font-family: $primary-font-family;
  font-size: $tertiary_font_size;
}

.default_color {
  color: $secondary-text-color;
}

.error_color {
  color: $error-color;
}

.character_length {
  font-weight: $bold-font-weight;
  margin: 0 4px;
}
