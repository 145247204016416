@import "../../../../shared/styles/colors.scss";

.header {
  background-color: $dense-gray-color;
  font-weight: bold;
}

.title_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: $dense-gray-color;
  font-weight: bold;
}

.title_icon_container {
  margin: 0px 10px;
}

.title_icon {
  vertical-align: text-top;
}
