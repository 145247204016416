@import "@/shared/styles/colors.scss";
@import "@/shared/styles/fonts.scss";

.collapse {
  background-color: $white-color;
  border-bottom: 1px solid $light-gray-color;
  padding-bottom: 8px;
  :global {
    .ant-collapse-item {
      .ant-collapse-header {
        padding: 0;
      }
    }
    .ant-collapse-content-box {
      padding: 16px !important;
      margin-top: 8px;
      background-color: $bg-light-gray-color;
    }
  }
}

.collapse_arrow_icon {
  width: 20px;
  height: 20px;
}
