@import "@/shared/styles/fonts.scss";

.file_upload_wrapper {
  padding-top: 30px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
