@import "@/shared/styles/colors.scss";
@import "@/shared/styles/fonts.scss";

.event_application {
  border: 1px solid $secondary-border-color;
  gap: 16px;
  border-radius: 4px;
  padding: 32px 24px;
  width: 100%;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.event_checked {
  margin-bottom: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 8px;
  font-family: $primary-font-family;
  font-weight: $bold-font-weight;
  font-size: $heading2_font_size;
}

.event_check_icon {
  color: $success-color;
  width: 21px;
  height: 21px;
}

.event_application_buttons {
  display: flex;
  gap: 8px;
  flex-direction: column;
  text-align: center;
}
