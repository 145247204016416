@import "../../../styles/colors.scss";

.button {
  cursor: pointer;
  padding: 4px 15px;
  background-color: $white-color;
  border: 1px solid $primary-color;
  border-radius: 3px;
  color: $primary-color;
  font-weight: bold;
  transition: 0.3s;
  &:hover {
    border: 1px solid $primary-color-hover;
    color: $primary-color-hover;
    transition: 0.3s;
  }
}
