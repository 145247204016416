$primary-color: #1f86e9;
$primary-color-hover: #40a9ff;
$secondary-color: #b5b5b5;
$secondary-color-hover: #808080;
$normal-text-color: #323232;
$sub-text-color: #808080;
$error-color: #d60000;
$error-color-hover: #ff4d4f;
$error-color-background: #ffeded;
$success-color: #00bf76;
$placeholder-color: #808080;
$secondary-border-color: #dddddd;
$focused-option-color: rgba(0, 191, 118, 0.1);
$secondary-tag-background-color: #ebebeb;
$supplementary-tag-label-color: #007b4c;
$supplementary-tag-background-color: rgba(0, 191, 118, 0.06);
$selected_option_color: rgba(0, 191, 118, 0.4);
$selected_text_color: #078353;

$dark_border-color: #323232;

$gray-color: #bdbdbd;
$light-gray-color: #dddddd;
$bg-light-gray-color: #f8f8f8;
$dense-gray-color: #475865;
$action-button-background-color: #ffffff;
$white-color: #fff;
$disabled-button-background: #ebebeb;
$grid-header-background: #ebebeb;
$grid-row-border: #ebebeb;
$black-color: #323232;
$success-transparent-color: rgba(0, 191, 118, 0.1);
$error-transparent-color: rgba(214, 0, 0, 0.1);
$primary-text-color: #0069ce;
$secondary-text-color: #6a6a6a;
$disable-text-color: #d9d9d9;
$hover-background-color: rgba(233, 237, 245, 0.25);
