@import "@/shared/styles/colors.scss";
@import "@/shared/styles/fonts.scss";

.control {
  font-family: $primary-font-family;
  &_primary {
    gap: 16px;
  }
  &_secondary {
    gap: 8px;
  }
}

.select_input {
  caret-color: transparent;
  cursor: pointer;
}

.select_value_container {
  border: none;
}

.select_indicators_container {
  color: $black-color;
}

:export {
  black_color: $black-color;
  bold_font_weight: $bold-font-weight;
  normal_font_weight: $normal-font-weight;
  primary_font_size: $emphasis_font_size;
  secondary_font_size: $secondary_font_size;
}
