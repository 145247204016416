@import "@/shared/styles/colors.scss";
@import "@/shared/styles/fonts.scss";

.event_location {
  display: flex;
  align-items: center;
  gap: 10px;
  font-family: $primary-font-family;
  font-style: normal;
  font-weight: $normal-font-weight;
  font-size: $secondary_font_size;
  line-height: 24px;
  word-break: break-all;
}

.icon_wrapper {
  width: 34px;
  min-width: 34px;
  height: 34px;
  background: $bg-light-gray-color;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pin_icon {
  width: 18px;
  height: 18px;
}
