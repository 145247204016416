@import "../../../../shared/styles/colors.scss";

.grid_and_actions {
  background-color: $white-color;
  padding: 32px 24px;
  &_inner {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
}

.examination_info_count {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 16px;
  justify-content: space-between;
  align-items: center;
}

.review_buttons {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 8px;
}

.filter_and_sort_actions {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-right: 40px;
  gap: 16px;
}

.select_boxes {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.select_box_container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 16px;
  margin-bottom: 16px;
}

.pagination_container {
  display: flex;
  justify-content: space-between;
}

.phase_tab_group_container {
  margin-bottom: 24px;
}

.card_header {
  display: flex;
  justify-content: space-between;
}

.buttons_container {
  gap: 8px;
}
