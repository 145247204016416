.rpv-full-screen-exit-button {
  bottom: 0;
  padding: 8px;
  position: fixed;
  right: 0;
}
.rpv-full-screen-exit-button-inner {
  background: #fff;
}
.rpv-full-screen-zoomin-button {
  bottom: 40px;
  padding: 8px;
  position: fixed;
  right: 0;
}
.rpv-full-screen-zoomin-button-inner {
  background: #fff;
}
.rpv-full-screen-zoomout-button {
  bottom: 80px;
  padding: 8px;
  position: fixed;
  right: 0;
}
.rpv-full-screen-zoomout-button-inner {
  background: #fff;
}
