@import "@/shared/styles/colors.scss";

.checkbox {
  width: 18px;
  height: 18px;
}

.checkbox_unchecked {
  border: 1px solid $dark_border-color;
  cursor: pointer;
}

.checked {
  cursor: pointer;
  accent-color: $primary-color;
}

.checkbox_disabled {
  cursor: not-allowed;
}
