@import "../../../../shared/styles/colors.scss";

.modal {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modal_body_container {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 800px;
}

.modal_body {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 400px;
}

.buttons_container {
  width: 100%;
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 40px;
}
