@import "../../../../shared/styles/colors.scss";
@import "../../../../shared/styles/fonts.scss";

.footer_link_container {
  width: 100%;
  text-align: center;
  padding: 10px 0;
}

.styled_link {
  color: #666;
  text-decoration: none;
  font-size: 0.8em;

  &:hover {
    color: #333;
  }
}
