@import "@/shared/styles/colors.scss";
@import "@/shared/styles/fonts.scss";

.blank_result_label {
  color: $sub-text-color;
  font-family: $primary-font-family;
  font-size: $secondary_font_size;
  font-weight: $bold-font-weight;
}

.review_result_label {
  color: $normal-text-color;
  font-family: $primary-font-family;
  font-size: $secondary_font_size;
  font-weight: $bold-font-weight;
}
