@import "@/shared/styles/colors.scss";
@import "@/shared/styles/fonts.scss";

.event_remaining {
  margin-bottom: 0;
  font-weight: $bold-font-weight;
  font-size: $tertiary_font_size;
}

.event_first_come_first_served {
  font-family: $primary-font-family;
  font-size: $secondary_font_size;
  line-height: 24px;
}

.event_remaining_count {
  font-size: $emphasis_font_size;
}
