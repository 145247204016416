@import "../../../../shared/styles/colors.scss";

.primary {
  background-color: $primary-color;
  &:hover {
    background-color: $primary-color-hover;
  }
}

.secondary {
  background-color: $secondary-color;
  &:hover {
    background-color: $secondary-color-hover;
  }
}

.danger {
  background-color: $error-color;
  &:hover {
    background-color: $error-color-hover;
  }
}

.action {
  background-color: $action-button-background-color;
  border: 1px solid $primary-color;
  &:hover {
    background-color: $action-button-background-color;
  }
}
