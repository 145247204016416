.delete_btn {
  margin: 20px 10px 10px 0;
}

.cancel_btn {
  margin: 20px 10px 10px 0;
}

.content_container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.text {
  white-space: pre-line;
  text-align: start;
}

.user_name {
  font-weight: bold;
}

.message_list {
  padding-left: 20px;
}

.message_item {
  list-style-type: disc;
  margin-bottom: 10px;
}

.message_space {
  margin-top: 24px;
}
