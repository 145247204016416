@import "@/shared/styles/colors.scss";
@import "@/shared/styles/fonts.scss";

.member_name_link {
  display: inline;
  color: $normal-text-color;
  font-family: $primary-font-family;
  font-size: $tertiary_font_size;
  font-weight: $normal-font-weight;
  margin-right: 16px;
}

.owner_name_link {
  display: inline;
  color: $normal-text-color;
  font-family: $primary-font-family;
  font-size: $tertiary_font_size;
  font-weight: $bold-font-weight;
  margin-right: 16px;
}

.names_container {
  width: fit-content;
  max-width: 380px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  @media (max-width: 1024px) {
    max-width: 200px;
  }
}
