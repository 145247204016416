@import "../../../../shared/styles/colors.scss";
@import "../../../../shared/styles/fonts.scss";

.inputWrapper {
  display: flex;
  align-items: center;
  height: 40px;
  padding: 10px;
  background: $white-color;
  border: 1px solid $light-gray-color;
  border-radius: 4px;
  font-size: $secondary_font_size;
}

.icon {
  height: 18px;
  width: 18px;
}

.timeInput {
  border: none;
  outline: none;
  text-align: center;
  width: 50px;
}

.invalid {
  border: 1px solid $error-color-hover;
}
