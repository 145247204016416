.rdw-dropdownoption-default {
  min-height: 25px;
  display: flex;
  align-items: center;
  padding: 0 5px;
}
.rdw-dropdownoption-highlighted {
  background: #f1f1f1;
}
.rdw-dropdownoption-active {
  background: #f5f5f5;
}
.rdw-dropdownoption-disabled {
  opacity: 0.3;
  cursor: default;
}
