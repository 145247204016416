@import "@/shared/styles/colors.scss";
@import "@/shared/styles/fonts.scss";

.cancel_btn {
  font-family: $primary-font-family;
  font-weight: $bold-font-weight;
  font-size: $secondary_font_size;
  line-height: 24px;
  color: $normal-text-color;
  border: 1px solid $secondary-border-color;
}
