@import "@/shared/styles/colors.scss";
@import "@/shared/styles/fonts.scss";

.select_box_control {
  font-style: normal;
  font-size: $secondary_font_size;
  font-family: $primary-font-family;
  border: none;
  box-sizing: border-box;
}

.select_input {
  caret-color: transparent;
  cursor: pointer;
}

.select_indicators_container {
  color: $black-color;
}

:export {
  border_color: $black-color;
  checked_border_color: $selected_option_color;
  normal_font_weight: $normal-font-weight;
  secondary_font_size: $secondary_font_size;
  checked_label_color: $selected_text_color;
}
