.event_thumbnail {
  display: flex;
  justify-content: center;
  position: relative;
  overflow: hidden;
}

.event_thumbnail_blur {
  background-size: cover;
  filter: blur(20px);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.event_thumbnail_image {
  max-width: 100%;
  height: 369px;
  z-index: 1;
}
