@import "../../../styles/colors.scss";
@import "../../../styles/fonts.scss";

.option_list_container {
  width: 100%;
  max-height: 300px;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 16px 0;
}

.list_item_button {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 8px 16px;
  background-color: transparent;
  border: 0;
  gap: 0 8px;
  cursor: pointer;
  &:hover {
    background-color: $hover-background-color;
  }
}
.button_label {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: $primary-font-family;
  font-size: $secondary_font_size;
  line-height: 24px;
  text-align: left;
  &_checked {
    color: $primary-text-color;
  }
}

.checkbox_unchecked {
  visibility: hidden;
}
