@import "@/shared/styles/colors.scss";
@import "@/shared/styles/fonts.scss";

.heading3 {
  margin-bottom: 0px;
  font-family: "Noto Sans JP";
  font-style: normal;
  font-weight: $bold-font-weight;
  font-size: $heading3-font-size;
  line-height: 160%;
}
