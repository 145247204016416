@import "@/shared/styles/colors.scss";
@import "@/shared/styles/fonts.scss";

.category_name_tag {
  width: fit-content;
  height: 20px;
  padding: 2.5px 8px;
  background-color: $secondary-tag-background-color;
  box-sizing: border-box;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.category_name_label {
  font-size: $tag_font_size;
  font-family: $primary-font-family;
  font-weight: $normal-font-weight;
  text-align: center;
  color: $normal-text-color;
}
