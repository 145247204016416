/*Small devices (landscape phones, 576px and up)*/
/*Medium devices (tablets, 768px and up)*/
/*Large devices (desktops, 992px and up)*/
/*Extra large devices (large desktops, 1200px and up)*/
/* From Line CSS */
/* prettier-ignore */
@import './colors';

@keyframes "shake-vertical" {
  10%,
  90% {
    transform: translate3d(0, -1px, 0);
  }
  20%,
  80% {
    transform: translate3d(0, 2px, 0);
  }
  30%,
  50%,
  70% {
    transform: translate3d(0, -4px, 0);
  }
  40%,
  60% {
    transform: translate3d(0, 4px, 0);
  }
}
@keyframes "shake-horizontal" {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }
  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }
  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }
  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}
/*Small devices (landscape phones, 576px and up)*/
/*Medium devices (tablets, 768px and up)*/
/*Large devices (desktops, 992px and up)*/
/*Extra large devices (large desktops, 1200px and up)*/
.contentRoot {
  background: $insu-bg-light-blue;
  min-height: 280px;
  width: 100%;
  float: right;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 100px 50px;
  @media only screen and (min-width: 680px) {
    width: 510px;
    height: 100vh;
  }
  @media only screen and (max-width: 600px) {
    width: 600px;
    height: 100vh;
  }
  @media only screen and (max-width: 500px) {
    width: 448px;
    height: 100vh;
  }
}

/*layout css*/
.ant-layout-content {
  background-color: $insu-grey-bg;
  margin: 0;
}
.ant-layout-content.authContent {
  background-color: white;
  margin: 0;
}
.ant-layout-header {
  height: 73px;
  background-color: $white-color;
  padding: 0;
}
.ant-layout-footer {
  background-color: $insu-grey-bg !important;
}
.horizontalLine {
  width: 366px;
  color: #cccccc;
}

.logo {
  background: url(https://upload.wikimedia.org/wikipedia/commons/a/ab/Android_O_Preview_Logo.png)
    no-repeat 0 0;
  width: 200px;
  height: 60px;
  background-size: contain;
  background-position: center;
}
.logoBlank {
  width: 200px;
  height: 60px;
}
/* side menu */
.ant-layout-sider-zero-width-trigger {
  margin-top: 12px;
}
.ant-layout-sider-children {
  min-height: 100vh;
}
.ant-layout-sider {
  background-color: $insu-grey-bg;
}
.ant-layout-sider-wide {
  flex: 0 0 400px !important;
  max-width: 17vw !important;
  min-width: 5vw !important;
  width: unset !important;
}
.ant-menu.ant-menu-dark {
  .ant-menu-item {
    border-left-color: transparent;
    border-left-width: 5px !important;
    border-left-style: solid;
  }

  .ant-menu-submenu-title {
    border-left-color: transparent;
    border-left-width: 5px !important;
    border-left-style: solid;
    padding-left: 17px !important;

    .ant-menu-submenu-arrow {
      opacity: 1;
      right: 10px;
    }
  }
  .ant-menu-item-selected {
    background-color: $insu-navy !important;
  }
  // .ant-menu-title-content {
  //     text-overflow: unset;
  // }
  color: $insu-light-grey;
  .ant-menu-sub {
    color: $insu-light-grey;
    background-color: $side-bg !important;
    .ant-menu-item {
      padding-left: 60px !important;
      padding-right: 9px !important;
      margin: 0px !important;
      padding-top: 8px !important;
      padding-bottom: 8px !important;
    }
  }
  .rootLogoImage {
    background-color: $mild-black !important;
    display: flex;
    height: 73px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin: 0px !important;
    padding: 0px !important;
    border: 0px !important;
  }
}

.main-menu {
  background-color: $side-bg !important;
  min-height: 100%;
}
.main-float-menu {
  background-color: $side-bg !important;
}

.project-menu > .ant-menu.ant-menu-light {
  margin-bottom: -24px;
  border-bottom: 0px;

  > .ant-menu-item {
    border-bottom: 0px;
    text-align: center;
    width: 120px;

    .ant-menu-title-content > a {
      color: #808080;
    }

    &:after {
      border-bottom-width: 3px;
      border-bottom-style: solid;
      right: 0px;
      bottom: 0px;
      left: 0px;
    }
  }

  > .ant-menu-item.ant-menu-item-selected {
    border-bottom-width: 0px !important;
    .ant-menu-title-content a {
      color: $insu-black;
    }
  }
}

.ant-menu-submenu-popup.ant-menu-dark {
  .ant-menu-item-selected {
    background-color: $insu-navy !important;
  }
}

.ant-menu-item.ant-menu-item-selected {
  background-color: $insu-navy;
  color: $insu-light-grey !important;
  border-left-color: $insu-primary !important;
  border-left-width: 5px;
  border-left-style: solid;
  background-color: transparent !important;
  .anticon {
    + {
      span {
        color: $insu-light-grey !important;
      }
    }
    color: $insu-light-grey !important;
  }
}

.projectheader {
  background-color: $side-bg;
  padding: 15px;
  color: $white-color;
  overflow: hidden;
  align-items: center;
  display: flex;
  height: 73px;
}

.myproject-menu-item {
  padding-left: 10px !important;
}
.myproject-menu-item.ant-menu-item-selected {
  padding-left: 4px !important;
}

.project-name {
  padding-left: 1vw !important;
}
.company-name {
  padding-left: 1vw !important;
}

.menu-line-divider {
  width: 100%;
  border-bottom-color: $insu-gray;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}

.dot {
  height: 30px;
  width: 30px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  margin-right: 12px;
}

/* */

.ant-card-head {
  background: $mild-black 0% 0% no-repeat padding-box;
  color: $white-color;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.GridViewProjectFiles .ant-card-head {
  background-color: white;
  color: $white-color;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border: none;
}

/* button layout */
.ant-btn {
  font-weight: 700;
  border: none;
  border-radius: 4px !important;
  background-color: transparent;
}
.ant-btn.ant-btn-link {
  font-weight: 400;
}
.ant-btn-ghost {
  color: white;
  background-color: rgba(181, 181, 181, 1);
  border-color: rgba(181, 181, 181, 1);
  // &:hover {
  //     color: #fafafa;
  //     background-color: rgba(181, 181, 181, 1);
  //     border-color: rgba(181, 181, 181, 1);
  // }
  // &:focus {
  //     color: #fafafa;
  //     background-color: rgba(181, 181, 181, 1);
  //     border-color: rgba(181, 181, 181, 1);
  // }
}
.add-milestone-btn {
  border-color: $dark-grey-color !important;
  border-style: dashed !important;
  border-width: 1px !important;
}
.notification-header {
  display: flex;
  align-items: center;

  .all-read-btn {
    margin-left: 10px;
    border-color: $mild-black !important;
    border-width: 1px !important;
    border-style: solid !important;
  }
}
.ant-btn-primary {
  color: $white-color;
  background-color: $insu-primary;
  border-color: $insu-primary;
}
.primary-outline {
  border-color: $insu-primary !important;
  border-width: 1px !important;
  border-style: solid !important;
  background-color: transparent;
  color: $insu-primary;

  &:hover {
    border-color: $insu-primary !important;
    border-width: 1px !important;
    border-style: solid !important;
    background-color: transparent;
    color: $insu-primary;
  }

  &:focus {
    border-color: $insu-primary !important;
    border-width: 1px !important;
    border-style: solid !important;
    background-color: transparent;
    color: $insu-primary;
  }
}
.offset-right-16 {
  margin-right: 16px;
}
.offset-right-4 {
  margin-right: 4px;
}
.offset-left-16 {
  margin-left: 16px;
}
.ant-btn-link {
  color: $insu-primary;
}
.ant-form-item-label {
  font-weight: 700;
}
/* */
.ant-modal-title {
  color: $mild-black;
  font-weight: 700;
}
.ant-modal-header {
  background-color: $insu-gray;
}
.ant-modal-close {
  color: $mild-black;
}
.ant-collapse-header {
  background-color: $grey-color;
}
.ant-collapse {
  > .ant-collapse-item {
    border: none;
  }
}
.ant-comment-content-author-time {
  color: $mild-black;
}
// Table
.ant-table-thead {
  > tr {
    > th {
      background-color: $insu-table-header-bg !important;
    }
  }
}
.notification-item {
  padding: 22px 40px 22px 16px;
}
.notification-item-deleted {
  padding: 22px 40px 22px 16px;
  background-color: rgba(50, 50, 50, 0.3);
}
.notification-item:hover {
  background: rgba(250, 250, 250, 1);
}
.ant-pagination-item {
  border-radius: 4px !important;
}
.ant-input {
  font-size: 14px;
  padding: 8px 16px;
  border-radius: 4px;
  border-color: #bbbbbb !important;
  min-height: 46px;
}

.ant-input-affix-wrapper {
  font-size: 14px;
  padding: 8px 16px;
  border-radius: 4px;
  border-color: #bbbbbb !important;
  min-height: 46px;
}

.ant-input-affix-wrapper > input.ant-input {
  min-height: unset;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 4px !important;
  border: 1px solid #dddddd;
  font-size: 14px;
  border-radius: 4px;
  border-color: #bbbbbb !important;
  padding: 8px 16px;
  min-height: 46px;
}
.ant-picker {
  min-height: 46px;
  border-radius: 4px;
  border-color: #bbbbbb !important;
}

.ant-input::placeholder {
  color: #808080;
  opacity: 1; /* Firefox */
}

.ant-input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #808080;
}

.ant-input::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #808080;
}

.ant-select-selection-placeholder {
  color: #808080;
}

span.ant-input-wrapper.ant-input-group {
  input {
    border-radius: 4px;
    border-color: #ebebeb;
  }

  span button {
    background-color: $insu-green-grey;
    min-width: 4.9vw;
    border-bottom-left-radius: 0 !important;
    border-top-left-radius: 0 !important;
    height: 46px;
  }
}
.ant-pagination-item-active {
  color: $white-color;
  background-color: $insu-green-grey;
  border-color: $insu-green-grey;
  a {
    color: $white-color;
    background-color: $insu-green-grey;
  }
}

.container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 1rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  line-height: 2;
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
    &:checked {
      ~ {
        .checkmark {
          background-color: rgba(31, 134, 233, 0.5);
          &:after {
            display: block;
          }
        }
      }
    }
  }
  &:hover {
    input {
      ~ {
        .checkmark {
          background-color: $checkbox-bg-color;
        }
      }
    }
  }
  .checkmark {
    &:after {
      left: 12px;
      top: 7px;
      width: 5px;
      height: 10px;
      border: solid $dark-grey-color;
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }
}
.container.project-task {
  margin-bottom: unset;
}
[data-first-draggable-item~="true"] {
  position: relative;
  box-shadow:
    0 4px 8px 0 rgba(0, 0, 0, 0.2),
    0 6px 20px 0 rgba(0, 0, 0, 0.19);
  left: 1px;
  border-width: 1px !important;
  border-style: solid !important;
  border-color: $insu-grey-bg !important;
  z-index: 12;
}
.await-file-upload-container {
  text-align: center;
  background-color: transparent;
  border-style: solid;
  border-width: 1px;
  border-radius: 4px;
  border-color: $insu-primary;
  cursor: pointer;
  padding-top: 3px;
  padding-bottom: 3px;
  color: $insu-primary;
  width: 12vw;
}
.await-file-upload-file-preview {
  display: flex;
  border-radius: 4px;
  padding: 7px;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-top: 7px;
  margin-bottom: 1vh;
  border-color: $dark-grey-color;
}
.await-file-upload {
  border: 1px solid $grey-color;
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
  background-color: $green-color;
  border-radius: 30px;
  font-weight: bolder;
  color: $white-color;
}
.checkmark {
  position: absolute;
  top: 6px;
  left: 0;
  height: 18px;
  width: 18px;
  background-color: $checkbox-bg-color;
  border-radius: 1px;
  &:after {
    content: "";
    position: absolute;
    display: none;
    left: 7px !important;
    top: 4px !important;
  }
}
label {
  cursor: text;
}
.pagetitle {
  padding-left: 0px !important;
  border-bottom: 0px !important;
  border-left: 0px !important;
  cursor: default;
  &:hover {
    padding-left: 0px !important;
    border-bottom: 0px !important;
    border-left: 0px !important;
    cursor: default;
  }
  &::after {
    padding-left: 0px !important;
    border-bottom: 0px !important;
    border-left: 0px !important;
    cursor: default;
  }
  &:hover::after {
    padding-left: 0px !important;
    border-bottom: 0px !important;
    border-left: 0px !important;
    cursor: default;
  }
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 4px !important;
    border: 1px solid #dddddd;
    font-size: 14px;
    border-radius: 4px;
    background-color: #fcfcfc !important;
    padding: 0px 10px;
    min-height: 32px;
  }
}

.ant-input-disabled {
  color: $disabled-grey-color !important;
  background-color: $grey-color !important;
  cursor: not-allowed !important;
  opacity: 1 !important;
}
.ant-comment-actions {
  > li {
    position: absolute;
    top: 0px;
    right: 0px;
  }
}
.ant-upload.ant-upload-drag {
  border: 1px dashed #1f86e9 !important;
}
.rdw-editor-main {
  // min-height: 200px;
  h1 {
    display: block;
    font-size: 2em !important;
    margin-block-start: 0.67em !important;
    margin-block-end: 0.67em !important;
    margin-inline-start: 0px !important;
    margin-inline-end: 0px !important;
    font-weight: 500 !important;
  }
  h4 {
    font-size: 1.08em !important;
  }
}
.shortEditor {
  min-height: 1em !important;
}
.rdw-embedded-modal {
  height: auto !important;
  width: 300px !important;
}
.rdw-suggestion-dropdown {
  min-width: 300px !important;
  box-shadow:
    0 4px 8px 0 rgba(0, 0, 0, 0.2),
    0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.rdw-link-modal {
  height: auto !important;
  width: 300px !important;
}
.rdw-image-modal {
  height: auto !important;
  width: 400px !important;
  box-shadow: 0px 0px 0px $grey-color !important;
  .rdw-image-modal-btn {
    width: auto !important;
    padding-bottom: 35px !important;
  }
}
.rdw-embedded-modal-btn {
  width: auto !important;
  padding-bottom: 35px !important;
}
.rdw-link-modal-btn {
  width: auto !important;
  padding-bottom: 35px !important;
}
.has-error {
  .ant-input {
    background-color: $white-color;
    border-color: $light-blue !important;
    background-color: $white-color;
    border-color: $light-blue !important;
    -webkit-box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2) !important;
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2) !important;
    &:hover {
      background-color: $white-color;
      border-color: $light-blue !important;
    }
    &:focus {
      background-color: $white-color;
      border-color: $light-blue !important;
      -webkit-box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2) !important;
      box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2) !important;
    }
  }
}
.analytic-card {
  div {
    &:first-child {
      background-color: $purple-color;
      border-radius: 5px;
      margin-right: 12px;
    }
    .anticon {
      padding: 12px;
      color: $white-color;
      font-size: 3rem;
    }
  }
}
.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}
.mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}
div.wrapper-class {
  width: 100%;
}
div.editor-class.rdw-editor-main {
  min-height: 130px !important;
  background: #fcfcfc;
  border: 1px solid #dcdcdc;
  border-radius: 4px;
  padding: 12px 12px;
}
div.editor-class-comment.rdw-editor-main {
  min-height: 130px !important;
  background-color: rgba(0, 0, 0, 0.04) !important;
  border-width: 1px !important;
  border-color: $dark-grey-color !important;
  border-radius: 3px !important;
  border-style: solid;
  padding-left: 12px;
  padding-right: 12px;
}

div.editor-class-linkify.rdw-editor-main {
  min-height: 130px !important;
  background-color: white !important;
  border: #caced5 1px solid !important;
  border-radius: 3px !important;
  padding-left: 12px;
  padding-right: 12px;
}

div.editor-class-linkify-nonedit.rdw-editor-main {
  min-height: 70px !important;
  background-color: white !important;
  border: #caced5 1px solid !important;
  border-radius: 3px !important;
  padding-left: 12px;
  padding-right: 12px;
}

div.editor-class-linkify-read.rdw-editor-main {
  min-height: 50px !important;
  background-color: white !important;
  padding-left: 0px;
  padding-right: 0px;
}

.rdw-block-dropdown {
  width: 125px !important;
}
.rdw-fontsize-dropdown {
  min-width: 46px !important;
}
.project-detail-hypothesis {
  background: white;
  padding: 16px 0px;
  button {
    border: 0;
    color: $white-color;
    font-weight: bold;
    padding: 4px 8px;
    border-radius: 4px;
    line-height: 1.5715;
  }
}

/* tab bar */
.ant-tabs.ant-tabs-card {
  .ant-tabs-card-bar {
    .ant-tabs-tab-active {
      background-color: $white-color;
      color: $mild-black;
    }
  }
}

.ant-tabs-card {
  .ant-tabs-nav {
    .ant-tabs-tab {
      background-color: $insu-gray;
      border: 1px solid #dddddd;
      box-sizing: border-box;
      color: $mild-black;
      width: 13rem;
      text-align: center;
      margin-left: 0px !important;
    }
    .ant-tabs-tab-active {
      background-color: $white-color;
      border: 1px solid $white-color;
      box-sizing: border-box;
      color: $mild-black;
    }
  }
}

.ant-tabs-tab.ant-tabs-tab-active {
  .ant-tabs-tab-btn {
    color: $mild-black;
    font-weight: 400;
  }
}
.ant-tabs-tab-btn {
  width: 100%;
}

.ant-tabs-tab {
  color: #808080 !important;
  width: 166px;
  font-weight: 400;
  text-align: center;
}

.ant-tabs-ink-bar {
  height: 4px !important;
  background: $insu-black;
}
.ant-tabs-content {
  background-color: $white-color;
  padding: 3%;
}
.ant-tabs-bar {
  margin-bottom: 0 !important;
}
.ant-tabs-top {
  > .ant-tabs-nav {
    margin: 0;
  }
  > div {
    > .ant-tabs-nav {
      margin: 0;
    }
  }
}
.ant-tabs-bottom {
  > .ant-tabs-nav {
    margin: 0;
  }
  > div {
    > .ant-tabs-nav {
      margin: 0;
    }
  }
}
/* tab bar */
.ant-card-body {
  background-color: $white-color;
}
.player-wrapper {
  position: relative;
  padding-top: 56.25%;
}
.react-player {
  position: absolute;
  top: 0;
  left: 0;
}

.ant-upload-list-item-error {
  color: $green-color !important;
  .ant-upload-text-icon {
    > .anticon {
      color: $green-color !important;
    }
  }
  .ant-upload-list-item-name {
    color: $green-color !important;
  }
}
input {
  &:focus {
    border-color: $green-color !important;
  }
}

.ant-upload-text {
  font-weight: 700;
}

.ant-upload-hint {
  font-size: 12px !important;
  color: $mild-black !important;
}

.on-hover-effect {
  cursor: pointer;
}
.comment-sheet-container-float {
  width: auto !important;
  display: flex;
  flex-grow: 1;
  padding: 0% 2.6% 0px;
  z-index: 100;
  .ant-card-body {
    -webkit-box-shadow: 0px -3px 2px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px -3px 2px rgba(0, 0, 0, 0.1);
    box-shadow: 0px -3px 2px rgba(0, 0, 0, 0.1);
    border-color: rgb(202, 206, 213);
    border-width: 0.1px;
    border-style: solid;
  }
}
.comment-sheet-floating {
  left: 200px !important;
  right: 0px !important;
  bottom: 0px;
  position: fixed;
}
.comment-sheet-container-fixed {
  width: auto !important;
  display: flex;
  flex-grow: 1;
  position: relative;
  padding: 0% 0% 0px;
  margin-top: 24px;
}
.aspect-ratio-box {
  position: relative;
  padding-bottom: 55%;
}
.aspect-ratio-box-inside {
  position: absolute;
  object-fit: cover;
  width: 100%;
  height: 100%;
}
#components-table-demo-drag-sorting {
  tr.drop-over-downward {
    td {
      border-bottom: 2px dashed $light-blue;
    }
  }
  tr.drop-over-upward {
    td {
      border-top: 2px dashed $light-blue;
    }
  }
}
.table-striped-rows {
  tr {
    &:nth-child(2n) {
      td {
        background-color: $grey-color;
      }
    }
  }
  thead {
    background-color: $grey-color;
  }
}
.ant-carousel {
  .slick-dots {
    li {
      button {
        background: $white-color;
        opacity: 0.4;
        height: 8px;
        width: 8px;
        border-radius: 4px;
      }
    }
    li.slick-active {
      button {
        opacity: 1;
        background: $white-color;
        height: 8px;
        width: 8px;
        border-radius: 4px;
      }
    }
  }
  .slick-dots-bottom {
    bottom: 4vh;
  }
}
.archive-split-row {
  .ant-table-cell {
    padding: 0;
    min-width: 3px;
  }
}
div.project-archive-split {
  div[role="presentation"] {
    width: 70px;
  }
}

.comment-badge {
  text-align: center;
  color: $white-color;
  font-size: 9px;
  font-weight: bold;
  min-width: 15px;
  min-height: 13px;
  border-radius: 50%;
  padding: 3px;
  background-color: $red-color;
}
.comment-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  flex-grow: 1;
  overflow: visible;
  margin-top: 16px;
}
.comments-wrapper {
  display: flex;
  overflow: visible;
  flex-direction: column;
  flex-grow: 100;
}
.comment-editor-wrapper {
  display: flex;
  flex-grow: 1;
  overflow: auto;
  margin-top: 16px;
}
.comment-icon-18px {
  display: flex;
  width: 18px;
  height: 18px;
}
.comment-icon-20px {
  display: flex;
  width: 20px;
  height: 20px;
}
.comment-drag-item {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 5px;
  padding-bottom: 5px;
}
.comment-top-item {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.comment-top-title {
  font-size: 14px;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.65);
  margin-left: 12px;
  cursor: pointer;
}
.comment-input-box {
  display: flex;
  flex: 1;
  margin-right: 16px;
  margin-left: 16px;
  background-color: rgba(0, 0, 0, 0.04);
  color: rgba(0, 0, 0, 0.3);
  border-width: 1px;
  border-style: solid;
  border-color: $grey-color;
  border-radius: 3px;
  height: 32px;
  font-size: 12px;
}
.comment-input-box-holder {
  display: flex;
  flex: 1;
  margin-right: 16px;
  margin-left: 16px;
  height: 32px;
}
.project-item-header {
  padding-left: 4px;
  padding-right: 4px;
  padding-top: 0px;
  padding-bottom: 0px;
  align-items: center;
}
.project-top {
  .ant-card-head-title {
    white-space: normal;
  }
  .ant-collapse {
    > .ant-collapse-item {
      > .ant-collapse-header {
        padding: 12px 0px !important;
      }
    }
  }
  .ant-collapse-content {
    > .ant-collapse-content-box {
      padding-right: 0px;
      padding-left: 0px;
      padding-top: 0px;
      padding-bottom: 32px;
    }
  }
  .tag-title {
    margin-right: 8px;
    padding: 2px 12px;
    border-radius: 3px;
    border: solid 1px $dark-grey-color;
    background-color: $grey-color;
    color: $mild-black;
    font-size: 11px;
  }
  .status-title-private {
    padding: 3px 12px;
    border-radius: 3px;
    background-color: $dark-grey-color;
    color: $white-color;
    font-weight: bold;
    font-size: 12px;
  }
  .status-title-public {
    padding: 3px 12px;
    border-radius: 3px;
    background-color: $dark-grey-color;
    color: $white-color;
    font-weight: bold;
    font-size: 12px;
  }
  .like-wrapper {
    display: flex;
    align-items: center;
    padding: 7px 22px;
    border-radius: 4px;
    border: 1px solid #1f86e9;
    background-color: $white-color;
    cursor: pointer;
    width: 77px;
    height: 40px;
  }
  .avatar-name-wrapper {
    display: flex;
    align-items: center;
    white-space: nowrap;
    background-color: $grey-color;
    border-radius: 100px;
    padding-right: 1vw;
    padding-left: 2px;
    padding-top: 2px;
    padding-bottom: 2px;
    margin-right: 0.5vw;
  }
  .ant-card-bordered {
    border: 0px;
  }
}
.meta-title {
  padding: 2px 12px;
  border-radius: 3px;
  border: solid 1px $purple-color;
  background-color: $light-background-color;
  color: $purple-color;
  font-size: 13px;
}

.reminder-title {
  display: flex;
  padding: 8px 12px;
  border-radius: 3px;
  background: rgba(4, 189, 119, 0.1);
  color: $insu-black;
  font-size: 14px;
  justify-content: center;
}
.milestone-title {
  padding: 8px 12px;
  border-radius: 3px;
  border: solid 1px $purple-color;
  background-color: $grey-color;
  color: $purple-color;
  font-size: 14px;
}
.invitation-title {
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  padding: 1px 12px;
  border-radius: 3px;
  background: rgba(4, 189, 119, 0.1);
  color: #1f86e9;
  font-size: 14px;
  font-family: Noto Sans JP;
  font-style: normal;
  font-weight: bold;
  line-height: 200%;
}
.notification-title {
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  padding: 0px 12px;
  border-radius: 3px;
  background: rgba(4, 189, 119, 0.1);
  color: $insu-primary;
  cursor: pointer;
  font-family: Noto Sans JP;
  font-style: normal;
  font-weight: bold;
  line-height: 200%;
}
.line-in-middle {
  height: inherit;
  background: linear-gradient(
    to right,
    transparent 0%,
    transparent calc(50% - 0.53px),
    $insu-primary calc(50% - 0.5px),
    $insu-primary calc(50% + 0.5px),
    transparent calc(50% + 0.53px),
    transparent 100%
  );
}
.line-in-point {
  width: 0;
  height: 0;
  border-bottom: 8px solid $grey-color;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  margin-left: auto;
  margin-right: auto;
}
.just-line {
  width: 0;
  height: 0;
  border-bottom: 1px solid $grey-color;
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
  margin-left: auto;
  margin-right: auto;
}
.circle-dot {
  position: absolute;
  height: 10px;
  width: 10px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: $insu-primary;
  border-radius: 50%;
  display: inline-block;
}
.circle-dot-outline {
  position: absolute;
  height: 10px;
  width: 10px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border-radius: 50%;
  border-width: 1px;
  border-style: solid;
  border-color: $insu-primary;
  display: inline-block;
}
.circle-dot-dash-outline {
  position: absolute;
  height: 10px;
  width: 10px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border-radius: 50%;
  border-width: 1px;
  border-style: dashed;
  border-color: $insu-primary;
  display: inline-block;
}
.pin-dot {
  height: 26px;
  width: 26px;
  position: absolute;
  font-size: 32px;
  display: inline-block;
  &:hover {
    animation: shake-horizontal 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    perspective: 1000px;
  }
  &:focus {
    animation: shake-horizontal 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    perspective: 1000px;
  }
}
.number-dot {
  position: absolute;
  height: 18px;
  width: 18px;
  border-radius: 50%;
  background-color: $insu-primary;
  display: flex;
  justify-content: center;
  align-items: center;
}
.number-span {
  line-height: 18px;
  font-size: 10px;
  font-weight: 700;
  color: white;
  vertical-align: middle;
}
.card-number-dot {
  height: 28px;
  width: 28px;
  border-radius: 50%;
  background-color: $insu-primary;
  display: flex;
  justify-content: center;
  align-items: center;
}
.card-number-span {
  line-height: 22px;
  font-size: 16px;
  font-weight: 700;
  color: white;
  vertical-align: middle;
}
.text-overflow-content {
  max-width: 180px;
  overflow: hidden;
  text-overflow: ellipsis;
  height: auto;
  max-height: 20px;
  white-space: nowrap;
  font-size: 9px;
  color: $black-color;
}
.project-archive-tutor-modal {
  .ant-modal-content {
    background-color: transparent !important;
    -webkit-box-shadow:
      0 0px 0px 0px rgba(0, 0, 0, 0.12),
      0 6px 16px 0 rgba(0, 0, 0, 0.08),
      0 0px 0px 0px rgba(0, 0, 0, 0.05) !important;
    box-shadow:
      0 0px 0px 0px rgba(0, 0, 0, 0.12),
      0 6px 16px 0 rgba(0, 0, 0, 0.08),
      0 0px 0px 0px rgba(0, 0, 0, 0.05) !important;
  }
  .ant-carousel {
    .slick-dots-bottom {
      bottom: 3vh !important;
    }
  }
}
.ProjectEditScreen {
  .alpha-cloud-form-group {
    margin-bottom: 6vh;
  }
}
.ProjectCreateScreen {
  .alpha-cloud-form-group {
    margin-bottom: 6vh;
  }
}
@media (min-width: 576px) {
  .card-video-wrapper-view {
    width: 100%;
  }
  .card-video-wrapper-view-new {
    width: 100vw;
  }
  .card-video-wrapper-view-img {
    width: 100vw;
    height: 56.25vw;
  }
  .edit-comment-model {
    min-width: 200px;
  }
  .card-archive-wrapper-view {
    width: 100%;
    min-width: 100%;
  }
  .archive-card-container {
    padding-left: 20px;
    padding-right: 20px;
    max-width: 80%;
  }
}
@media (max-width: 768px) {
  .comment-sheet-container {
    left: 0px !important;
  }
}
@media (min-width: 768px) {
  .card-video-wrapper-view {
    width: 50%;
  }
  .card-video-wrapper-view-new {
    width: 50vw;
  }
  .card-video-wrapper-view-img {
    width: 50vw;
    height: 23vw;
  }
  .edit-comment-model {
    min-width: 400px;
  }
  .card-archive-wrapper-view {
    width: 80%;
    min-width: 80%;
  }
  .archive-card-container {
    padding-left: 20px;
    padding-right: 20px;
    max-width: 80%;
  }
}
@media (min-width: 992px) {
  .card-video-wrapper-view {
    width: 33.3%;
  }
  .card-video-wrapper-view-new {
    width: 33.3vw;
  }
  .card-video-wrapper-view-img {
    width: 33.3vw;
    height: 18.7vw;
  }
  .edit-comment-model {
    min-width: 500px;
  }
  .card-archive-wrapper-view {
    width: 60%;
    min-width: 60%;
  }
  .archive-card-container {
    padding-left: 20px;
    padding-right: 20px;
    max-width: 80%;
  }
}
@media (min-width: 1200px) {
  .card-video-wrapper-view {
    width: 25%;
  }
  .card-video-wrapper-view-new {
    width: 25vw;
  }
  .card-video-wrapper-view-img {
    width: 25vw;
    height: 14vw;
  }
  .edit-comment-model {
    min-width: 700px;
  }
  .card-archive-wrapper-view {
    width: 40%;
    min-width: 40%;
  }
  .archive-card-container {
    padding-left: 20px;
    padding-right: 20px;
    max-width: 80%;
  }
}
.emoji-picker-react {
  z-index: 300;
}
a.disabled {
  pointer-events: none;
  cursor: default;
}

// Quill editor style

.ql-container.ql-snow {
  border: 1px solid #bbbbbb !important;
  border-radius: 4px !important;
}

.ql-editor {
  font-size: 14px !important;
  color: rgba(0, 0, 0, 0.85) !important;
  line-height: 1.5715 !important;
  padding: 8px 16px !important;
  min-height: 40px;
  border-color: #bbbbbb !important;
  border-radius: 4px !important;
}

.ql-editor-read {
  font-size: 14px !important;
  color: rgba(0, 0, 0, 0.85) !important;
  padding: 4px 11px !important;
}

.ql-editor.ql-blank::before {
  color: rgba(0, 0, 0, 0.3) !important;
  content: attr(data-placeholder) !important;
  pointer-events: none !important;
  position: absolute !important;
  font-style: normal !important;
}

.ja.ql-snow .ql-tooltip::before {
  content: "リンク先URL:" !important;
}

.ja.ql-snow .ql-tooltip a.ql-action::after {
  content: "リンク先URLを編集" !important;
}

.ja.ql-snow .ql-tooltip a.ql-remove::before {
  content: "リンクを解除" !important;
}

.ja.ql-snow .ql-tooltip.ql-editing a.ql-action::after {
  content: "保存" !important;
}
/* prettier-ignore */
.ja.ql-snow .ql-tooltip[data-mode=link]::before {
    content: 'URLを入力:' !important;
}

.en.ql-snow .ql-tooltip::before {
  content: "Visit URL:" !important;
}

.en.ql-snow .ql-tooltip a.ql-action::after {
  content: "Edit" !important;
}

.en.ql-snow .ql-tooltip a.ql-remove::before {
  content: "Remove" !important;
}

.en.ql-snow .ql-tooltip.ql-editing a.ql-action::after {
  content: "Save" !important;
}
/* prettier-ignore */
.en.ql-snow .ql-tooltip[data-mode=link]::before {
    content: 'Enter link:' !important;
}
.custom-pagination {
  .display-container {
    display: flex;
    align-items: center;
  }
  .display-lbl {
    color: black;
  }
  .display-title {
    color: #0b132e;
    font-weight: 700;
    margin-left: 16px;
    margin-right: 10px;
  }
  .display-option-container {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .display-select {
    min-width: 100px;
  }
  .pagination-container {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    float: right;
  }
  .pagination-item {
    margin-bottom: 0px;
  }
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    padding: 5px 10px;
    min-height: 40px;
  }
}

.library-management {
  .management-item {
    background: #ffffff;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    margin-bottom: 16px;
    padding: 14px 17px;
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    color: #323232;
    cursor: pointer;
  }
}
.disabled-text-box {
  border: 1px solid #d9d9d9 !important;
  border-radius: 2px !important;
  min-height: 15vh;
  padding: 10px 13px;
  width: 100%;
}
.library-news {
  .like-btn {
    background: #ffffff;
    box-sizing: border-box;
    border-radius: 3px;
    border: 1px solid #000;
    font-family: Noto Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;
    padding: 6px 12px;
    cursor: pointer;
    .disabled {
      color: #c4c4c4 !important;
    }
    .enabled {
      color: #000000 !important;
    }
    .liked {
      color: $green-color;
    }
  }
}
.option-updirection > .rdw-dropdown-optionwrapper {
  max-height: 120px;
  bottom: 150px;
}

.ant-tag {
  margin: 8px 6px 8px 0px;
  padding: 4px 10px;
}

.user-type-tag {
  border: none;
  background-color: rgba($color: #323232, $alpha: 0.1);
}

.profile-form {
  .ant-tag {
    border-color: #323232;
  }
}

.tag-selected {
  border-color: $insu-primary !important;
  color: $insu-primary;
}

.wrap-tag-text {
  white-space: pre-wrap;
  word-break: break-word;
}

.clickable-tag {
  cursor: pointer;
}

h4 {
  font-weight: bolder;
}

h2 {
  font-weight: 700;
}
.ant-collapse-header {
  background-color: #fff !important;
}
.user-email {
  background-color: #ffffff !important;
  margin-right: 16px;
}
.mid-header-size {
  font-family: Noto Sans JP;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 200%;
  color: #323232;
}
.ant-menu-dark .ant-menu-inline.ant-menu-sub {
  ::before {
    content: "";
    position: absolute;
    width: 1px;
    background: #717171;
    top: 0px;
    bottom: 0px;
    left: 25px;
  }
  li::before {
    content: "";
    position: absolute;
    height: 1px;
    background: #717171;
    top: 50%;
    width: 19px;
    left: 26px;
  }
  li:last-child {
    ::before {
      content: "";
      position: absolute;
      width: 1px;
      background: #717171;
      top: 0px;
      bottom: 50%;
      left: 25px;
    }
  }
}

.ant-checkbox-inner {
  border: 1px solid #323232;
}
.ant-table-filter-dropdown {
  max-width: 70vw !important;
}

.ant-table-x-scroll {
  overflow-x: auto;
  white-space: nowrap;

  .ant-table-cell {
    white-space: normal;
    word-break: keep-all; // 日本語の改行を防ぐ
  }
}
