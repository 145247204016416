@import "../../../../shared/styles/colors.scss";

.file_name {
  font-weight: 400;
  margin-left: 4px;
  font-size: 12px;

  &_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 6px 10px;
    border-radius: 3px;

    &_base_color {
      background-color: $success-transparent-color;
    }
    &_error_color {
      background: $error-transparent-color;
    }
  }
}

.left_contents {
  display: flex;
  align-items: center;
}
