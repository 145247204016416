@import "@/shared/styles/colors.scss";
@import "@/shared/styles/fonts.scss";

.review_result_cell_container {
  background-color: transparent;
  box-sizing: border-box;
  border: none;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  grid-gap: 4px;
  width: 100%;
  height: 100%;
}

.review_result_cell_inner_row {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  column-gap: 16px;
}
