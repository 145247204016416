@import "@/shared/styles/colors.scss";
@import "@/shared/styles/fonts.scss";

.modal_container {
  margin: 0 24px;
}
.section_title {
  font-size: $primary_font_size;
  font-weight: $bold-font-weight;
  color: $black-color;
}
.section_container {
  margin-bottom: 24px;
}
.login_type {
  &_container {
    display: flex;
    align-items: center;
    gap: 0 16px;
  }
  &_label_with_radio {
    display: flex;
    align-items: center;
  }
  &_radio_button {
    margin-right: 8px;
  }
}

.list {
  &_container {
    padding: 0;
  }
  &_items {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid $light-gray-color;
    padding: 8px 0;
    &:first-child {
      padding-top: 0;
    }
  }
}
.delete_button {
  padding: 4px 16px;
  color: $black-color;
  font-weight: $bold-font-weight;
}
.add_ip {
  &_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 0 8px;
  }
  &_input {
    width: 0;
    padding: 4px 8px;
    border: 1px solid $light-gray-color;
    border-radius: 4px;
    &_error {
      border-color: $error-color;
    }
  }
  &_address_input {
    flex-grow: 1;
  }
  &_cidr_input {
    flex-grow: 0.2;
  }
}
