@import "../../../../shared/styles/colors.scss";
@import "../../../../shared/styles/fonts.scss";

.calendar {
  :global {
    .react-datepicker__header {
      border-bottom: none;
      background-color: $white-color;
    }
    .react-datepicker__day-name {
      color: $normal-text-color;
    }
    .react-datepicker__month {
      margin-top: 0;
    }
    .react-datepicker__day:hover {
      border-radius: 100%;
    }
    .react-datepicker__day--selected {
      border-radius: 100%;
      background-color: $primary-color;
    }
    .react-datepicker__day--keyboard-selected {
      border-radius: 100%;
    }
    .react-datepicker__day--outside-month {
      color: $sub-text-color;
    }
  }
  font-family: $primary-font-family;
  color: $normal-text-color;
}

.calendar_header {
  display: flex;
  justify-content: space-between;
  padding: 0 1em;
  border-bottom: none;
  margin-bottom: 0.5em;
}

.navigation {
  border: none;
  cursor: pointer;
  background-color: $white-color;
  color: $sub-text-color;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 0.2em;
}

.navigation_icon {
  height: 1.5em;
  width: 1.5em;
}

.current_month {
  margin-left: 0.3em;
  font-size: $heading3-font-size;
  font-weight: $bold-font-weight;
}

.arrows_container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.calendar_container {
  background: $white-color;
  border: 1px solid $light-gray-color;
}
