@import "../../../../shared/styles/colors.scss";

.item {
  width: 100%;
}

.item_container {
  width: 100%;
  border-radius: 8px;
  padding: 1rem;
  border: 1px solid;
  cursor: auto;
  border-color: $light-gray-color;
}

.sortable {
  border: 1px dashed;
  cursor: grab;
  border-color: $light-gray-color;
}

.active {
  border-color: $primary-color;
  background-color: rgba($primary-color, 0.2);
}

.form_container {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.content_container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.left_container {
  display: flex;
  overflow: hidden;
  width: 100%;
}

.item_header {
  display: flex;
  justify-content: center;
  align-items: center;
}

.phase_name_text {
  font-family: "Noto Sans JP";
  font-style: normal;
  font-weight: 700;
  font-size: 21px;
  line-height: 30px;
  color: #323232;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.icon_container {
  margin: 0 30px;
  padding-bottom: 10px;
}

.input_container {
  display: flex;
}

.btn_container {
  display: flex;
  flex-direction: row;
}

.save_btn {
  margin: 10px;
}

.cancel_btn {
  margin: 10px 10px 10px 0;
}

.action_btn_container {
  display: flex;
}

.delete_icon {
  margin-right: 10px;
}

.edit_icon {
  margin-right: 10px;
}
