.input_wrapper {
  position: relative;
}

.input_icon {
  position: absolute;
  height: 18px;
  width: 18px;
  margin: 11px 10px 11px 10px;
}

.input {
  padding-left: 38px;
}
