@import "@/shared/styles/fonts.scss";
@import "@/shared/styles/colors.scss";

.submission_modal {
  &_info_text {
    font-family: $primary-font-family;
    font-size: $emphasis_font_size;
    font-weight: $bold-font-weight;
  }
  &_body_container {
    margin: 0px 200px;
    font-family: $primary-font-family;
  }
  &_info_container {
    display: flex;
    flex-direction: column;
    gap: 32px 0;
  }
  &_button {
    flex: 1;
    font-weight: $bold-font-weight;
  }
}

.labels {
  display: flex;
  gap: 10px;
}

.file_url_container {
  background-color: $bg-light-gray-color;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.label_with_input {
  display: flex;
  flex-direction: column;
  gap: 8px;
  font-size: $secondary_font_size;
}

.specify_label {
  display: flex;
  align-items: center;
  gap: 8px;
  margin: 8px 0;
}

.submission_elements {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 32px 0px;
  font-family: $primary-font-family;
  font-size: $secondary_font_size;
  line-height: 24px;
}
.submission_element_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 8px 0px;
}

.count_label {
  font-size: 12px;
  color: $secondary-text-color;
}

.buttons_container {
  display: flex;
  gap: 0px 8px;
  justify-content: center;
  margin-top: 64px;
}
:export {
  modal_body_padding: 64px 0;
}
