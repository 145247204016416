$primary-font-family: "Noto Sans JP", sans-serif;
$bold-font-weight: 700;
$normal-font-weight: 400;

$heading2_font_size: 21px;
$heading3-font-size: 18px;
$emphasis_font_size: 18px;
$large_font_size: 24px;
$primary_font_size: 16px;
$secondary_font_size: 14px;
$tertiary_font_size: 12px;
$tag_font_size: 10px;
