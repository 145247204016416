.switch_btn {
  margin-left: 10px;
}

.switch_btn_label {
  font-weight: bold;
}

.btn_container {
  margin-top: 10px;
}

.cancel_btn {
  margin-left: 10px;
}
