@import "@/shared/styles/colors.scss";

.event_info {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.event_info_inputs {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.event_type_select_box {
  max-width: 400px;
}
