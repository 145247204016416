@import "@/shared/styles/colors.scss";
@import "@/shared/styles/fonts.scss";

.phase_tab_container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: fit-content;
}

.phase_tab {
  padding: 6px 36px;
  font-size: 16px;
  font-family: $primary-font-family;
  background-color: transparent;
  box-sizing: border-box;
  border: none;
  cursor: pointer;
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  transition: all 0.4s ease-in-out;
}

.phase_tab_selected {
  color: $primary-color;
  font-weight: $bold-font-weight;
}

.phase_tab_not_selected {
  color: $black-color;
  font-weight: $normal-font-weight;
}

.phase_tab_underline {
  border-top: none;
  border-right: none;
  border-left: none;
  border-radius: 3px;
  transition: all 0.4s ease-in-out;
  width: 100%;
}

.phase_tab_underline_selected {
  border-bottom: 2px solid $primary-color;
}

.phase_tab_underline_not_selected {
  border-bottom: 1px solid $secondary-border-color;
}
