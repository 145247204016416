@import "../../../../shared/styles/colors.scss";

.message {
  margin-top: 10px;
  white-space: pre-wrap;
  color: $error-color;
}

.message_container {
  display: flex;
  justify-content: space-between;
}
