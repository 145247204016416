.update_btn {
  margin: 20px 10px 10px 0;
}

.cancel_btn {
  margin: 20px 10px 10px 0;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.text {
  white-space: pre-line;
  text-align: center;
}
