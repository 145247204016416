.error_card {
  height: 100%;
}

.error_row {
  margin-top: 120px;
}

.description_column {
  margin-left: 40px;
}

.description_paragraph {
  margin-bottom: 0;
}
