@import "@/shared/styles/colors.scss";

.tag {
  padding: 2px 10px;
  color: $white-color;
  font-family: "Noto Sans JP";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  display: inline-block;
}

.green_tag {
  background-color: $success-color;
}

.gray_tag {
  background-color: $secondary-color;
}
