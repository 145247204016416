@import "@/shared/styles/fonts.scss";

.pagination {
  &_container {
    display: flex;
    align-items: center;
    gap: 16px;
    font-family: $primary-font-family;
  }
  &_button {
    border: none;
    background: none;
    cursor: pointer;
    padding: 0;
    color: transparent;
    &:disabled {
      cursor: not-allowed;
    }
  }
}
