@import "@/shared/styles/colors.scss";
@import "@/shared/styles/fonts.scss";

.review_info_grid_header_container {
  background-color: $grid-header-background;
  box-sizing: border-box;
  border: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 8px 24px;
  gap: 32px;
  width: 100%;
  height: 48px;
}

.checkbox_header {
  flex-grow: 0;
  flex-shrink: 0;
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  background-color: $grid-header-background;
}

.project_info_header {
  flex-grow: 2.5;
  flex-shrink: 0;
  flex-basis: 0;
  display: flex;
  flex-direction: row;
  height: 100%;
  align-items: center;
  justify-content: flex-start;
}

.review_info_header {
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 0;
  display: flex;
  flex-direction: row;
  height: 100%;
  align-items: center;
  justify-content: flex-start;
}
