@import "@/shared/styles/fonts.scss";
@import "@/shared/styles/colors.scss";

.label_container {
  display: flex;
  align-items: center;
  gap: 8px;
  font-family: $primary-font-family;
  font-size: $emphasis_font_size;
  font-weight: $normal-font-weight;
  color: $black-color;
}

.count {
  font-size: $large_font_size;
  font-weight: 700;
}
